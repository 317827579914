
$(document).ready(function(){

	$(window).load(function(){
		AOS.init();
	});

	var $rats = $('.cbtn-inquire').data('job');
	$('.cbtn-inquire').click(function(){
		//alert();
        //$("#myModal").modal();
        //$('.txtSubject').val($rats);
    });

	// CODES THAT INCLUDE ON LOAD AND RESIZE AT THE SAME TIME
	var resizeTimer;
	$(window).on('load resize', function(e){
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {

			if($('.home').is(':visible')){
				$('.gallery-list').carouFredSel({
					items: {
						visible: {
							min: 1,
							max: 4
						}
					},
					prev: {
					       button  : ".serv-prev",
					       key : "left"
					},
					next: {
					       button  : ".serv-next",
					       key		: "right"
					},
					direction : "left",
					responsive: true,
					circular: true,
					auto: true,
					align: 'center',
					scroll : {
						items : 1,
						duration : 800,
						pauseOnHover : true
					},
				});
				$('.announcements').flexslider({
					animation: "slide",
					touch: true,
					slideshow: true,
					animationSpeed:2000,
					itemMargin: 0,
					itemWidth: '100%',
					minItems: 1,
					maxItems: 1,
					controlNav: false,
					customDirectionNav: false
				});
			}

			$('.cs-slides2').each(function() {
		        var ctrlPrev = $(this).closest('.cs-slider2').find('.prev');
		        var ctrlNext = $(this).closest('.cs-slider2').find('.next');
		        $(this).carouFredSel({
		        	auto: false,
		            width: '100%',
		            height: '100%',
		            responsive: $(this).data('tg-responsive'),
		            prev: ctrlPrev,
		            next: ctrlNext,
		            scroll: {
		                items: $(this).data('item-scroll'),
		                fx: $(this).data('effects'),
		                duration: parseInt(jQuery(this).data('duration') + '000'),
		            },
		            items: {
		                width: $(this).data('item-width'),
		                visible: {
		                    min: $(this).data('min'),
		                    max: $(this).data('max')
		                },
		            },
		           /* auto: {
		                timeoutDuration: parseInt(jQuery(this).data('timeout-duration') + '000'),
		                pauseOnHover: true
		            },*/
	        	});
	    	});

			/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
			if( Modernizr.mq('(min-width: 1200px)') ) {
				
			}
			else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
				// CODES FOR MEDIUM DEVICES HERE
				
			}
			else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
				// CODES FOR SMALL DEVICES HERE

			}
			else{
				// CODES FOR EXTRA SMALL DEVICES HERE

			}

		}, 250);
		(function () {
		  equalHeight(false);
		})();

		window.onresize = function(){
		  equalHeight(true);
		}
	});

	function equalHeight(resize) {
	  var elements = document.getElementsByClassName("equalHeight"),
	      allHeights = [],
	      i = 0;
	  if(resize === true){
	    for(i = 0; i < elements.length; i++){
	      elements[i].style.height = 'auto';
	    }
	  }
	  for(i = 0; i < elements.length; i++){
	    var elementHeight = elements[i].clientHeight;
	    allHeights.push(elementHeight);
	  }
	  for(i = 0; i < elements.length; i++){
	    elements[i].style.height = Math.max.apply( Math, allHeights) + 'px';
	    if(resize === false){
	      elements[i].className = elements[i].className + " show";
	    }
	  }
	}
});